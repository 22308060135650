import { initCaptcha, drawCodeImage } from "@/api/index";
export default {
  name: "imgCaptcha",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showModal: this.value,
      captchaId: "",
      code: "",
      captchaImg: ""
    };
  },
  methods: {
    show() {
      this.getCaptchaImg();
      this.showModal = true;
    },
    getCaptchaImg() {
      this.loading = true;
      initCaptcha().then(res => {
        this.loading = false;
        if (res.success) {
          this.code = "";
          this.captchaId = res.result;
          this.$emit("on-captchaId", this.captchaId);
          this.captchaImg = drawCodeImage + this.captchaId;
        }
      });
    },
    changeCode(v) {
      this.$emit("on-code", this.code);
    },
    submit() {
      if (!this.code) {
        this.$Message.error("请输入验证码");
        return;
      }
      this.$emit("on-sumbit", this.code);
      this.showModal = false;
    }
  },
  watch: {
    value(val) {
      if (val == true) {
        this.show();
      }
    },
    showModal(value) {
      this.$emit("input", value);
    }
  }
};