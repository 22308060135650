var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "relate"
  }, [_c('Row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "type": "flex",
      "justify": "center",
      "align": "middle"
    },
    nativeOn: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.showCaptcha.apply(null, arguments);
      }
    }
  }, [_c('Col', {
    staticClass: "content"
  }, [_c('div', [_c('Header'), !_vm.relateLoading ? _c('div', [_c('Tabs', {
    attrs: {
      "value": "1"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": _vm.$t('relateTitle'),
      "name": "1",
      "icon": "md-person-add"
    }
  }, [_c('Form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "username"
    }
  }, [_c('Input', {
    attrs: {
      "prefix": "ios-person-outline",
      "size": "large",
      "clearable": "",
      "placeholder": "请输入登录账号",
      "autocomplete": "off"
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "password"
    }
  }, [_c('Input', {
    attrs: {
      "type": "password",
      "prefix": "ios-lock-outline",
      "size": "large",
      "clearable": "",
      "placeholder": "请输入密码",
      "autocomplete": "off"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)], 1)], 1)], 1), _c('Row', [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "large",
      "loading": _vm.loading,
      "long": ""
    },
    on: {
      "click": _vm.showCaptcha
    }
  }, [!_vm.loading ? _c('span', [_vm._v(_vm._s(_vm.$t("relate")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("relating")))])])], 1), _c('Row', {
    staticClass: "other-thing",
    attrs: {
      "type": "flex",
      "justify": "space-between"
    }
  }, [_c('router-link', {
    staticClass: "back",
    attrs: {
      "to": "/reset"
    }
  }, [_vm._v(_vm._s(_vm.$t("forgetPass")))]), _c('router-link', {
    staticClass: "back",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("registerNow")) + " ")])], 1)], 1) : _vm._e(), _vm.relateLoading ? _c('div', [_c('RectLoading')], 1) : _vm._e()], 1), _c('Footer')], 1), _c('LangSwitch')], 1), _c('ImgCaptcha', {
    on: {
      "on-captchaId": function ($event) {
        _vm.form.captchaId = $event;
      },
      "on-code": function ($event) {
        _vm.form.code = $event;
      },
      "on-sumbit": _vm.submitRelate
    },
    model: {
      value: _vm.captchaModal,
      callback: function ($$v) {
        _vm.captchaModal = $$v;
      },
      expression: "captchaModal"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };